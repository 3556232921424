<template>
  <div class="container mt-4">
    <div class="flex">
      <router-link to="/main" class="mx-4 text-blue-500">
        <i class="fas fa-chevron-left font-semibold"></i>
        {{ $t("back") }}
      </router-link>
    </div>
    <div v-if="error" class="m-4 md:mx-24 text-red-700">{{ error }}</div>
    <form class="m-4 md:mx-24" @submit="onSubmit">
      <div class="flex items-center mb-4">
        <label class="w-24 font-semibold">{{ $t("edit_date") }}</label>
        <t-input
          class="flex-grow"
          disabled
          :value="$route.params.date"
        ></t-input>
      </div>
      <div class="flex items-center mb-4">
        <label class="w-24 font-semibold">{{ $t("edit_job") }}</label>
        <t-select
          class="flex-grow"
          v-model="form.jobId"
          :options="jobs"
          value-attribute="id"
          text-attribute="name"
          required
        ></t-select>
      </div>
      <div class="flex items-center mb-4" v-if="isEdit">
        <label class="w-24 font-semibold">{{ $t("edit_user") }}</label>
        <t-select
          class="flex-grow"
          v-model="form.userId"
          :options="userOptions"
          value-attribute="id"
          text-attribute="name"
        ></t-select>
        <div class="w-36 ml-5">
          <t-checkbox v-model="form.addEmployeeSchedule" />
          <span class="ml-1 font-semibold">{{
            $t("add_employee_schedule")
          }}</span>
        </div>
      </div>
      <div class="flex items-center mb-4" v-if="isEdit && fullSchedule.user">
        <label class="w-24 font-semibold">{{ $t("too_late") }}</label>
        <span v-if="fullSchedule.late" class="mr-2">
          {{ fullSchedule.late.minutes_to_late }}
        </span>
        <t-button
          type="button"
          variant="light-orange"
          :disabled="loading"
          @click="showLateFormModal"
        >
          {{ !fullSchedule.late ? $t("enter") : $t("update") }}
        </t-button>
      </div>
      <div class="flex items-center mb-4">
        <label class="w-24 font-semibold">{{ $t("edit_timeslot") }}</label>
        <div class="flex items-center flex-grow">
          <t-select
            class="w-26"
            v-model="form.start"
            :options="timeSlotOptions.start"
            required
          ></t-select>
          <span class="mx-4">{{ $t("until") }}</span>
          <t-select
            class="w-26"
            v-model="form.end"
            :options="timeSlotOptions.end"
            required
          ></t-select>
          <div class="w-36 ml-5" v-if="isEdit">
            <t-checkbox v-model="form.canceled" @click="cancelSchedule()" />
            <span class="ml-1 font-semibold">{{ $t("canceled") }}</span>
          </div>
        </div>
      </div>
      <cancel-modal
        v-model="showCancelModal"
        :cancelOptions="cancelReasons"
        :cancelReasonId="form.cancelReasonId"
        @submit="applyCancelReasonId($event)"
        @cancel="cancelRemoved($event)"
      ></cancel-modal>
      <div class="flex items-center mb-4" v-if="form.cancelReasonId">
        <label class="w-24 font-semibold">{{ $t("cancel_reason") }}</label>
        {{ selectedCancelReason.description }}
      </div>
      <div class="ml-24 flex items-center">
        <t-button variant="primary" :disabled="loading">{{
          $t("edit_save")
        }}</t-button>
        <t-button
          type="button"
          :disabled="loading"
          v-if="isEdit"
          class="text-sm ml-2"
          variant="danger"
          @click="onScheduleRemove"
        >
          {{ $t("remove") }}
        </t-button>
      </div>
    </form>
    <div v-if="isEdit" class="overflow-x-auto m-4 md:mx-24 mt-20">
      <label class="w-24 font-semibold"> {{ $t("history") }}</label>
      <t-table :headers="historyHeader" :data="history" class="mt-5">
        <template slot="row" slot-scope="props">
          <tr>
            <td :class="props.tdClass">{{ props.row.created_at | date }}</td>
            <td :class="props.tdClass">
              <template v-if="props.row.job">
                {{ props.row.job.NAME }}
              </template>
            </td>
            <td :class="props.tdClass">
              <template v-if="props.row.user">
                {{ props.row.user.NAME }}
              </template>
            </td>
            <td :class="props.tdClass">
              {{ props.row.from }} - {{ props.row.until | endTime }}
            </td>
            <td :class="props.tdClass">
              {{ props.row.add_employee_schedule | boolean }}
            </td>
            <td :class="props.tdClass">{{ props.row.canceled | boolean }}</td>
            <td :class="props.tdClass">
              {{ props.row.updated_at }}
            </td>
            <td :class="props.tdClass">
              <template v-if="props.row.admin">
                {{ props.row.admin.NAME }}
              </template>
            </td>
          </tr>
        </template>
        <template v-if="!history.length" v-slot:tbody="props">
          <tbody :class="props.tbodyClass">
            <tr :class="[props.trClass, 'text-center']">
              <td :class="props.tdClass" colspan="8">
                <t-alert show :dismissible="false" variant="error">
                  {{ $t("no_data_available_on_table") }}
                </t-alert>
              </td>
            </tr>
          </tbody>
        </template>
      </t-table>
    </div>
    <t-modal v-if="isEdit" v-model="showLateModal" :header="$t('too_late')">
      <div class="flex items-center mb-4">
        <label class="w-32 font-semibold">{{ $t("edit_job") }}</label>
        <span>{{ details.job_name }}</span>
      </div>
      <div class="flex items-center mb-4">
        <label class="w-32 font-semibold">{{ $t("edit_user") }}</label>
        <span>{{ details.user_name }}</span>
      </div>
      <div class="flex items-center mb-4">
        <label class="w-32 font-semibold">{{ $t("edit_date") }}</label>
        <span>{{ details.date }}</span>
      </div>
      <div class="flex items-center mb-4">
        <label class="w-32 font-semibold">{{ $t("from") }}</label>
        <span>{{ details.start }}</span>
      </div>
      <div class="flex items-center mb-4">
        <label class="w-32 font-semibold">{{ $t("until") }}</label>
        <span>{{ details.end }}</span>
      </div>
      <div class="flex items-center mb-4">
        <label class="w-32 font-semibold">{{ $t("late_minutes") }}</label>
        <t-input
          :class="{ 'border-red-300': hasError('minutes_to_late') }"
          class="flex-grow"
          v-model="lateForm.minutes_to_late"
          required
        />
      </div>
      <div class="flex items-center mb-4">
        <label class="w-32 font-semibold">{{ $t("late_reason") }}</label>
        <t-textarea
          v-model="lateForm.reason"
          :class="{ 'border-red-300': hasError('reason') }"
          class="flex-grow"
          rows="5"
          required
        ></t-textarea>
      </div>
      <div class="flex items-center mb-4" v-if="details.admin">
        <label class="w-32 font-semibold">{{ $t("late_reported_by") }}</label>
        <span>{{ details.admin }}</span>
      </div>
      <template v-slot:footer>
        <div class="flex justify-end">
          <t-button class="text-sm mr-2" @click="showLateModal = false">{{
            $t("close")
          }}</t-button>
          <t-button
            v-if="fullSchedule.late"
            class="text-sm mr-2"
            variant="danger"
            @click="onLateRemove"
          >
            {{ $t("remove") }}
          </t-button>
          <t-button class="text-sm" variant="primary" @click="onLateSubmit">{{
            $t("edit_save")
          }}</t-button>
        </div>
      </template>
    </t-modal>
  </div>
</template>

<script>
import CancelModal from "@/components/CancelModal.vue";
import { actions, getters } from "@/constants/state";
import { remove } from "@/services/fullschedule-service";
import * as lateService from "@/services/late-service";
import {
  getDayTranslation,
  getEndTimeDay,
  getTimeSlotOptions,
  getTimeText,
} from "@/utils/misc";
import * as _ from "lodash";
import moment from "@/utils/moment-utc";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { CancelModal },
  props: {
    fullSchedule: null,
    users: null,
    cancelReasons: null,
  },
  data: () => ({
    showLateModal: false,
    showCancelModal: false,
    loading: false,
    canceled: false,
    selectedCancelReason: null,
    form: {
      jobId: null,
      canceled: false,
      start: null,
      end: null,
      userId: null,
      addEmployeeSchedule: true,
      cancelReasonId: null,
    },
    lateForm: {
      errors: null,
      minutes_to_late: null,
      reason: null,
    },
    history: [],
    error: null,
  }),
  mounted() {
    this.form = {
      ...this.form,
      jobId:
        _.get(this.fullSchedule, "job_id") || _.get(_.first(this.jobs), "id"),
      userId: _.get(this.fullSchedule, "user_id"),
      canceled: _.get(this.fullSchedule, "canceled", false),
      cancelReasonId: _.get(this.fullSchedule, "cancel.cancel_reason_id", null),
      start: this.isEdit
        ? moment(_.get(this.fullSchedule, "start")).format("HH:mm")
        : "07:00",
      end: this.isEdit
        ? moment(_.get(this.fullSchedule, "ending")).format("HH:mm")
        : "02:00",
    };

    this.selectedCancelReason = _.find(this.cancelReasons, {
      id: this.form.cancelReasonId,
    });

    this.history = _.get(this.fullSchedule, "history");
  },
  methods: {
    ...mapActions({
      createFullSchedule: actions.FULLSCHEDULES_CREATE_ACTION,
      updateFullSchedule: actions.FULLSCHEDULES_UPDATE_ACTION,
    }),
    async onSubmit(e) {
      e.preventDefault();
      this.loading = true;
      this.isEdit
        ? await this.fullScheduleUpdate()
        : await this.fullScheduleCreate();
      this.loading = false;
    },
    async fullScheduleCreate() {
      try {
        const date = this.$route.params.date;
        await this.createFullSchedule({
          start: `${date} ${this.form.start}:00`,
          ending: getEndTimeDay(date, this.form.start, this.form.end),
          locationId: this.locationId,
          jobId: this.form.jobId,
        });
        this.$router.push("/main");
      } catch (error) {
        console.error("DEBUG: onSubmit", error);
      }
    },
    async fullScheduleUpdate() {
      try {
        const date = this.$route.params.date;
        await this.updateFullSchedule({
          id: this.fullSchedule.id,
          start: `${date} ${this.form.start}:00`,
          ending: getEndTimeDay(date, this.form.start, this.form.end),
          jobId: this.form.jobId,
          userId: this.form.userId || null,
          canceled: this.form.canceled,
          addEmployeeSchedule: this.form.addEmployeeSchedule,
          cancelReasonId: this.form.canceled ? this.form.cancelReasonId : null,
          locationId: this.locationId,
        });
        this.$router.push("/main");
      } catch (error) {
        console.error("DEBUG: onSubmit", error);

        if (_.get(error, "data.message")) {
          this.error = this.$i18n.t(_.get(error, "data.message"));
        }
      }
    },
    async onLateSubmit() {
      this.lateForm.errors = null;
      this.loading = true;
      try {
        const payload = {
          ..._.pick(this.lateForm, ["minutes_to_late", "reason"]),
          full_schedule_id: this.fullSchedule.id,
          admin_user_id: this.authUserId,
          user_id: this.fullSchedule.user_id,
        };

        !this.fullSchedule.late
          ? await lateService.create(payload)
          : await lateService.update(this.fullSchedule.late.id, payload);
        this.$router.push("/main");
      } catch (error) {
        console.error("DEBUG: onLateSubmit", error);
        if (error.status === 400) {
          this.lateForm.errors = _.get(error, "data.errors");
        }
      }
      this.loading = false;
      lateService.create;
    },
    async onScheduleRemove() {
      const { isOk } = await this.$dialog.confirm(
        this.$i18n.t("remove"),
        this.$i18n.t("are_you_sure"),
        "info"
      );

      if (!isOk) {
        return;
      }

      this.loading = true;
      try {
        await remove(this.fullSchedule.id);
        this.$router.push("/main");
      } catch (error) {
        console.error("DEBUG: onScheduleRemove", error);
      }
      this.loading = false;
    },
    async onLateRemove() {
      const { isOk } = await this.$dialog.confirm(
        this.$i18n.t("remove"),
        this.$i18n.t("late_confirm_remove") + "???",
        "info"
      );

      if (!isOk) {
        return;
      }

      this.loading = true;
      try {
        await lateService.remove(this.fullSchedule.late.id);
        this.$router.push("/main");
      } catch (error) {
        console.error("DEBUG: onLateRemove", error);
      }
      this.loading = false;
    },
    showLateFormModal() {
      this.lateForm = {
        errors: null,
        minutes_to_late: _.get(this.fullSchedule, "late.minutes_to_late") || 0,
        reason: _.get(this.fullSchedule, "late.reason"),
      };
      this.showLateModal = true;
    },
    hasError(fieldName) {
      return _.includes(this.lateForm.errors, fieldName);
    },
    cancelSchedule() {
      this.form.cancelReasonId = null;

      if (!this.form.canceled) {
        this.showCancelModal = true;
      }
    },
    applyCancelReasonId(reason) {
      this.selectedCancelReason = reason;
      this.form.cancelReasonId = _.get(reason, "id", null);
    },
    cancelRemoved() {
      this.showCancelModal = false;
      this.form.cancelReasonId = null;
      this.form.canceled = false;
    },
  },
  computed: {
    ...mapGetters({
      jobs: getters.JOBS_GETTER,
      locationId: getters.DOMAIN_LOCATION_ID_GETTER,
      authUserId: getters.AUTH_USER_ID_GETTER,
    }),
    hasUserSet() {
      return _.get(this.fullSchedule, "user");
    },
    details() {
      const start = moment(this.fullSchedule.start);
      return {
        user_name: _.get(
          _.find(this.users, { id: _.get(this.fullSchedule, "user_id") }),
          "name"
        ),
        job_name: _.get(
          _.find(this.jobs, { id: _.get(this.fullSchedule, "job_id") }),
          "name"
        ),
        date: `${getDayTranslation(start.isoWeekday())} ${start.format(
          "YYYY-MM-DD"
        )}`,
        start: getTimeText(start.format("HH:mm")),
        end: getTimeText(moment(this.fullSchedule.ending).format("HH:mm")),
        admin: _.get(this.fullSchedule, "late.admin_user.name"),
      };
    },
    timeSlotOptions() {
      return getTimeSlotOptions();
    },
    userOptions() {
      return [
        {
          id: null,
          name: "",
        },
        ..._.map(this.users, (user) => {
          const prefix = this.$i18n.t("free_day");
          if (user.free_day) {
            let from = moment(user.free_day.from).format("HH:mm");
            let until = moment(user.free_day.until).format("HH:mm");
            let name = user.name;
            if (from === "07:00" && until === "02:00") {
              name = `${user.name} (${prefix}: ${this.$i18n.t(
                "available_selected_hour_allday"
              )})`;
            } else if (from === "17:00" && until === "02:00") {
              name = `${user.name} (${prefix}: ${this.$i18n.t(
                "available_selected_hour_evening"
              )})`;
            } else {
              from = from === "07:00" ? this.$i18n.t("call") : from;
              from = from === "02:00" ? this.$i18n.t("closing") : from;
              until = until === "07:00" ? this.$i18n.t("call") : until;
              until = until === "02:00" ? this.$i18n.t("closing") : until;
              name = `${user.name} (${prefix}: ${from} - ${until})`;
            }

            return {
              ...user,
              name,
            };
          }

          if (user.free_day_unavailable) {
            return {
              ...user,
              name: `${user.name} (${prefix}: ${this.$t(
                "available_selected_hour_allday"
              )})`,
            };
          }

          return user;
        }),
      ];
    },
    historyHeader() {
      return [
        { text: this.$i18n.t("full_schedule_history_theader_published_date") },
        { text: this.$i18n.t("full_schedule_history_theader_job") },
        { text: this.$i18n.t("full_schedule_history_theader_assigned_user") },
        { text: this.$i18n.t("full_schedule_history_theader_timeslot") },
        { text: this.$i18n.t("full_schedule_history_theader_add_schedule") },
        { text: this.$i18n.t("full_schedule_history_theader_canceled") },
        { text: this.$i18n.t("full_schedule_history_theader_updated_at") },
        { text: this.$i18n.t("full_schedule_history_theader_updated_by") },
      ];
    },
    isEdit() {
      return !_.isEmpty(this.fullSchedule);
    },
  },
  filters: {
    date(value) {
      return moment(value).format("DD-MM-YYYY");
    },
    endTime(value) {
      return getTimeText(value);
    },
    boolean(value) {
      return value ? "Yes" : "No";
    },
  },
};
</script>
