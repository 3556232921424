import axios from "axios";
import { API_ENDPOINT } from "@/utils/misc";

export const create = (payload) => {
  return axios.post(`${API_ENDPOINT()}/lates`, payload);
};

export const update = (id, payload) => {
  return axios.post(
    `${API_ENDPOINT()}/lates/${id}?_method=PUT`,
    payload
  );
};

export const remove = (id) => {
  return axios.delete(`${API_ENDPOINT()}/lates/${id}`);
};
