<template>
  <t-modal
    hideCloseButton
    :clickToClose="false"
    :value="value"
    v-on="$listeners"
    :header="$t('select_the_cancellation_reason')"
  >
    <t-radio-group
      name="cancel"
      :options="cancelOptions"
      text-attribute="description"
      v-model="selectedCancelReasonId"
    >
    </t-radio-group>
    <template v-slot:footer>
      <div class="flex justify-end">
        <t-button
          type="button"
          class="mx-4"
          variant="primary"
          :disabled="!selectedCancelReasonId"
          @click="selectCancelReason()"
        >
          Select
        </t-button>
        <t-button type="button" @click="cancel()"> Close </t-button>
      </div>
    </template>
  </t-modal>
</template>

<script>
import * as _ from "lodash";

export default {
  name: "CancelModal",
  data: () => ({
    selectedCancelReasonId: null,
  }),
  props: {
    cancelOptions: {
      type: Array,
    },

    value: {
      type: Boolean,
    },

    cancelReasonId: {
      type: Number,
    },
  },

  watch: {
    cancelReasonId: {
      deep: true,
      async handler(id) {
        this.selectedCancelReasonId = id;
      },
    },
  },

  methods: {
    selectCancelReason() {
      const reason = _.find(this.cancelOptions, {
        id: this.selectedCancelReasonId,
      });
      this.$emit("input", false);
      this.$emit("submit", reason);
    },
    cancel() {
      this.$emit("cancel");
      if (!this.cancelReasonId) {
        this.selectedCancelReasonId = null;
      }
    },
  },
};
</script>